/**
 * @function 购物车计算函数
 * @param cartList {Array}
 * @return number
 */

export default function (cartList) {
  let sum = 0
  for (let i = 0; i < cartList.length; i++) {
    for (let j = 0; j < cartList[i].children.length; j++) {

    }
  }
  return sum
}
